import { graphql } from "gatsby"

import React from "react"
import Layout from "../layouts/Layout"
import DemoHero from "../components/DemoHero"
import Demo from "../components/Demo"
import SiteMetadata from "../components/SiteMetadata"
import DemoApp from "../components/DemoApp"
import looking from "../images/looking.png"
import ContactBanner from "../components/ContactBanner"

const DemoPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Zurly Demos for Fundraisers"
        description="Digital fundraiser and loyalty card features from Zurly"
      />

      <DemoHero
        h1={data.hero.h1Text}
        category={data.hero.handwrittenText}
        paragraph={data.hero.paragraphText.paragraphText}
        image={data.hero.svgImage}
        alt={data.hero.svgImage.description}
        button={data.hero.buttonText}
      />
      <Demo
        cards={data.demos.nodes}
        titleAlign="text-center"
        display="hidden"
        title="Fundraising Campaign Demos"
        bottomPadding="pb-48"
      />
      <div className="relative">
        <div className="floating-image">
          <img
            src={looking}
            alt="Curious about Zurly? Let's take a look at the demos below."
          />
        </div>
      </div>
      <DemoApp
        h1={data.app.h1Text}
        paragraph={data.app.paragraphText.paragraphText}
        button={data.app.buttonText}
      />
      <ContactBanner />
    </Layout>
  )
}

export default DemoPage

export const query = graphql`
  query {
    hero: contentfulHeros(handwrittenText: { eq: "EXPERIENCE ZURLY?" }) {
      handwrittenText
      h1Text
      buttonText
      paragraphText {
        paragraphText
      }
      svgImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
    app: contentfulHeros(handwrittenText: { eq: "Demos > Custom App" }) {
      h1Text
      buttonText
      paragraphText {
        paragraphText
      }
    }
    benefitsCards: allContentfulBenefitCard(
      filter: { section: { eq: "features fundraiser" } }
      limit: 2
    ) {
      nodes {
        featureBenefits
        featureTitle
        icon {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
          title
        }
      }
    }
    demos: allContentfulFeaturedDemo(filter: { tag: { eq: "fundraising" } }) {
      nodes {
        description
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          description
        }
        tag
        link
      }
    }
  }
`
