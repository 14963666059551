import React from "react";
import Button from './Button';

const DemoApp = ({h1,paragraph, button}) => {


    return(
      <div className="bg-white h-full lg:h-auto">
        <div className="relative px-6 lg:px-24 xl:px-40 pt-64 lg:pb-16 flex flex-col lg:flex-row text-center w-screen">
          <div className="w-full lg:w-1/2 xl:w-2/5 my-auto mx-auto flex-shrink pb-20">
            <h1>{h1}</h1>
            <p className="pt-5 pb-12">{paragraph}</p>
              <Button href="/solutions-custom">{button}</Button>
          </div>
        </div>
      </div>
    )
}

export default DemoApp
