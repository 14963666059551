import React from "react";
import Img from 'gatsby-image';
import Button from './Button';

const DemoHero = ({h1,category,paragraph,image, altImage, button}) => {


    return(
      <div className="bg-seagreen h-full lg:h-auto">
        <div className="px-6 lg:px-24 xl:px-40 pt-32 flex flex-col text-center lg:text-center lg:items-center w-screen">
          <div id="hero-image" className="w-full lg:w-1/2 p-6 xl:px-12 max-h-80">
            <Img fluid={image.fluid} alt={altImage}/>
          </div>
          <div className="w-full lg:w-1/2 xl:w-2/5 my-auto flex-shrink">
            <h1>{h1}</h1>
          </div>
        </div>
        <div className="bg-white w-screen flex flex-col items-center pb-20">
          <h1 className="permanent text-nectarine font-normal text-center">{category}</h1>
          <p className="max-w-lg text-center mt-5 mx-4">{paragraph}</p>
          <p className="max-w-lg text-center mt-5 mb-10 mx-4 font-bold">Click on a demo below to see how other organizations have used Zurly.</p>
        </div>
      </div>
    )
}


export default DemoHero
